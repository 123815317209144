@if (cartItem(); as item) {
  <div
    [class.minimize-height]="minimizeHeight()"
    [class.small-cart]="smallCart()"
    [disabled]="(showingRemoveItemModal$|async) || (loading$|async)"
    [grey]="true"
    [loading]="(loading$|async) ?? false"
    [small]="true"
    app-loading
    class="d-flex cart-item"
  >
    <div class="product-image">
      <img [class.sold-out-product-image]="soldOut()" [ngSrc]="productImageUrl()" alt="mangler billede" class="center"
           fill/>
    </div>
    <div class="d-flex flex-column flex-grow-1">
      <div class="d-flex h-100">
        <div class="info-wrapper">
          <a (click)="goToProduct()" [class.sold-out-product]="soldOut()">
            <p class="title mb-0">{{ item.title }}</p>
            <p class="sub-title mb-0">{{ item.name }}</p>
          </a>
          @if (item.preorderDate) {
            <span class="preorder-cart-item">
              @if (item.isEnPrimeur) {
                OBS: EN PRIMEUR-vare. Særlige vilkår gælder. Forventet leveret fra {{ item.preorderDate | date: 'd. MMMM y' }}
              } @else {
                OBS: Forsalgsvare. Sendes fra d. {{ item.preorderDate | date: 'd. MMMM y' }}
              }
            </span>
          }
          @if (!soldOut()) {
            <div class="qty-container">
              <p>Antal</p>
              <app-qty-input
                (qtyChange)="updateQty($event)"
                [enableDebounce]="true"
                [qty]="qty()"
                [disable]="!!item.isFree"
                [small]="smallQtyInput()"
              ></app-qty-input>
              @if (item.ctaText) {
                <p class="buy-more-text-desktop">{{ item.ctaText }}</p>
              }
            </div>
          }
        </div>
        <div class="product-prices">
          @if (!item.isFree) {
            <fa-icon (click)="showRemoveItemModal()" [icon]="faTrashCan" class="trash-icon"
                     role="button"></fa-icon>
          }
          @if (!soldOut()) {
            @if (item.ctaText) {
              <p class="buy-more-text-mobile">{{ item.ctaText }}</p>
            }
            <p [class.text-decoration-line-through]="item.qtyPriceBeforeDiscount !== item.compareAtPrice"
               class="normal-price">
              {{ item.compareAtPrice | price | currency }} kr. pr. {{ item.unit }}
            </p>
            @if (item.qtyPriceBeforeDiscount !== item.compareAtPrice) {
              <p class="discount-price">
                {{ item.qtyPriceBeforeDiscount | price | currency }} kr. pr. {{ item.unit }}
              </p>
            }
            <p class="total-price">{{ item.priceBeforeDiscount | price | currency }} kr.</p>
          }
        </div>
      </div>
      @if (notEnoughStock()) {
        <div class="not-enough-stock-alert">
          <fa-icon class="me-2" [icon]="faTriangleExclamation"/>
          Kun {{ item.salableQty }} på lager
          <span class="ms-2 me-1" (click)="updateCartQty(item.salableQty)">Opdatér</span>
          /
          <span class="mx-1" (click)="showRemoveItemModal()">Fjern</span>
        </div>
      }
      @if (soldOut()) {
        <div class="sold-out-alert">
          <fa-icon class="me-2" [icon]="faBan"/>
          Produktet er desværre udsolgt
          <span class="ms-2" (click)="showRemoveItemModal()">Fjern</span>
        </div>
      }
    </div>
  </div>
}
