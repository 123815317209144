import {Component, inject} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalContainerComponent} from '@layout/components/modals/modal-container/modal-container.component';

@Component({
  selector: 'app-remove-cart-item-modal',
  templateUrl: './remove-cart-item-modal.component.html',
  styleUrls: ['./remove-cart-item-modal.component.scss'],
  imports: [
    ModalContainerComponent
  ]
})
export class RemoveCartItemModalComponent {
  activeModal = inject(NgbActiveModal);

}
