import {Component, computed, effect, inject, input, output, signal} from '@angular/core';
import {AsyncPipe, CurrencyPipe, DatePipe, NgOptimizedImage} from '@angular/common';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {CartItem, CartItemStockStatus, Maybe} from '@core/interfaces/generated/graphql';
import {FormsModule} from '@angular/forms';
import {CartService} from '@core/services/cart.service';
import {take} from 'rxjs/operators';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {PricePipe} from '@core/pipes/price.pipe';
import {LoadingComponent} from '@layout/components/general/loading/loading.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RemoveCartItemModalComponent} from '../remove-cart-item-modal/remove-cart-item-modal.component';
import {GoogleService} from '@core/services/google.service';
import {QtyInputComponent} from '@layout/components/product/qty-input/qty-input.component';
import {faTrashCan} from '@fortawesome/free-regular-svg-icons';
import {faBan, faTriangleExclamation} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss'],
    imports: [
      NgOptimizedImage,
      FontAwesomeModule,
      CurrencyPipe,
      FormsModule,
      PricePipe,
      LoadingComponent,
      QtyInputComponent,
      AsyncPipe,
      DatePipe
    ]
})

export class CartItemComponent {
  private cartService = inject(CartService);
  private router = inject(Router);
  private modalService = inject(NgbModal);
  private googleService = inject(GoogleService);


  cartItem = input<Maybe<CartItem>>(null);
  smallCart = input(false);
  minimizeHeight = input(false);
  smallQtyInput = input(false);
  readonly goToProductEvent = output();

  loading$ = new BehaviorSubject(false);
  showingRemoveItemModal$ = new BehaviorSubject(false);

  modalSub: Subscription;

  qty = signal(1);

  soldOut = computed(() => {
    return this.cartItem()?.stockStatus === CartItemStockStatus.OutOfStock;
  });

  notEnoughStock = computed(() => {
    return this.cartItem()?.stockStatus === CartItemStockStatus.NotEnoughStock;
  });
  productImageUrl = computed(() => this.cartItem()?.image?.small || '');
  protected readonly faTriangleExclamation = faTriangleExclamation;
  protected readonly faBan = faBan;

  constructor() {
    effect(() => {
      this.qty.set(this.cartItem()?.qty || 1);
    });
  }

  updateCartQty(qty: number | null | undefined) {
    const sku = this.cartItem()?.sku;
    if (!this.cartItem || !sku || !qty) {
      return;
    }
    this.loading$.next(true);
    const qtyDiff = qty - (this.cartItem()?.qty || 0);
    const cartItem = this.cartItem();
    this.cartService.updateItemInCart(qty, sku).then(cart => {
      if (cartItem) {
        this.googleService.updateCartEvent(cartItem, qtyDiff);
      }
    }).finally(() => {
      this.loading$.next(false);
    }).catch((reason) => {
      this.qty.set(this.cartItem()?.qty || 1);
        throw reason;
      }
    );
  }

  updateQty(value: number) {
    this.qty.set(value);
    if (value < 1) {
      this.showRemoveItemModal();
      return;
    }
    this.updateCartQty(value);
  }

  removeFromCart() {
    const sku = this.cartItem()?.sku;
    if (!sku) {
      return;
    }
    this.loading$.next(true);
    const cartItem = this.cartItem();
    this.cartService.removeItemFromCart(sku).then(cart => {
      if (cartItem) {
        this.googleService.removeFromCartEvent(cartItem, cartItem.qty || 0);
      }
    }).finally(() => {
      this.loading$.next(false);
    }).catch(reason => {
      throw reason;
    });
  }

  protected readonly faTrashCan = faTrashCan;

  goToProduct() {
    const cartItem = this.cartItem();
    if (cartItem?.url) {
      this.goToProductEvent.emit();
      this.router.navigateByUrl(cartItem.url);
    }
  }

  showRemoveItemModal() {
    this.showingRemoveItemModal$.next(true);
    const modalReference = this.modalService.open(RemoveCartItemModalComponent);
    this.modalSub = modalReference.closed.pipe(take(1)).subscribe(data => {
      if (data) {
        this.removeFromCart();
      } else {
        this.qty.set(this.cartItem()?.qty ?? 1);
      }
    });
    modalReference.hidden.pipe(take(1)).subscribe(data => {
      this.showingRemoveItemModal$.next(false);
      this.modalSub.unsubscribe();
    });
  }
}
