import {Component, computed, inject, input} from '@angular/core';
import {Cart} from '@core/interfaces/generated/graphql';
import {CurrencyPipe} from '@angular/common';
import {environment} from '@env/environment';
import {formatPrice} from '@core/pipes/price.pipe';
import {GroupVoucherService} from '@core/services/groupVoucher.service';

@Component({
  selector: 'app-free-shipping-incentive',
  templateUrl: './free-shipping-incentive.component.html',
  styleUrls: ['./free-shipping-incentive.component.scss'],
  imports: [
    CurrencyPipe
  ]
})

export class FreeShippingIncentiveComponent {
  private groupVoucherService = inject(GroupVoucherService);


  cart = input<Cart>();

  showFreeDeliveryText() {
    return computed(() => {
      const cart = this.cart();
      if (!cart) {
        return false;
      }
      const groupVoucher = this.groupVoucherService.groupVoucherSignal()
      if (groupVoucher.groupVoucher?.delivery) {
        return false;
      }
      return this.untilFreeDelivery(cart) > 0 && this.isCartNotEmpty(cart) && this.cartIsNotVirtual(cart);
    });
  }

  isCartNotEmpty(cart: Cart) {
    return (cart.items?.length || 0) > 0;
  }

  cartIsNotVirtual(cart: Cart) {
    return !cart.isVirtual;
  }

  untilFreeDelivery(cart: Cart | undefined): number {
    if (!cart?.totalPrice) {
      return environment.supervin.freeDelivery;
    }
    return environment.supervin.freeDelivery - formatPrice(cart.totalPrice - (cart.shippingMethod?.price || 0));
  }
}
