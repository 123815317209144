@if (renderSignal()) {
  <div
    [attr.data-cart-id]="cartSignal().cart.cartId"
    [attr.data-label]="label()"
    [attr.data-product-id]="productId()"
    [attr.data-product-qty]="qty()"
    [attr.data-referer]="referer()"
    [attr.data-type]="type()"
    [attr.data-customer-token]="customerToken"
    class="superpay-buy-btn"></div>
}
