import {Component, computed, inject, input} from '@angular/core';
import {CartService} from '@core/services/cart.service';
import {UserInitService} from '@core/services/user-init.service';
import {GroupVoucherService} from '@core/services/groupVoucher.service';

import {UserService} from '@core/services/user.service';
import {environment} from '@env/environment';

@Component({
  selector: 'app-superpay-buy-btn',
  imports: [],
  templateUrl: './superpay-buy-btn.component.html',
  styleUrls: ['./superpay-buy-btn.component.scss']
})
export class SuperpayBuyBtnComponent {
  protected cartService = inject(CartService);
  protected initService = inject(UserInitService);
  private groupVoucherService = inject(GroupVoucherService);
  private userService = inject(UserService);


  readonly type = input<'checkout' | 'add-to-cart' | 'age-verification-embedded' | 'authentication'>('checkout');
  readonly productId = input<string>();
  readonly referer = input<string>();
  readonly label = input<string>(' - KLIK HER');
  readonly qty = input<number>();

  cartSignal = this.cartService.cartSignal;
  groupVoucherSignal = this.groupVoucherService.groupVoucherSignal;
  renderSignal = this.initRenderSignal();
  customerToken = this.userService.userToken;

  initRenderSignal() {
    return computed(() => {
      if (!environment.superpay.enabled) {
        return false;
      }

      const cart = this.cartSignal()?.cart;
      const userInit = this.initService.onInitializedSignal();
      const type = this.type();
      if (type === 'authentication' && userInit) {
        return true;
      }
      if (!cart?.id) {
        return false;
      }
      if (!this.initService.onInitializedSignal()) {
        return false;
      }
      if (this.groupVoucherSignal().groupVoucher?.delivery === 1 && type === 'checkout') {
        return false;
      }
      return this.cartService.getTestCase(cart, 'show_sp_iframe_btn') === 1;
    })
  }

}
